// config/shopify.js
import Client from 'shopify-buy';

const client = Client.buildClient({
    domain: '2f9315-3.myshopify.com',
    storefrontAccessToken: 'c5ced78e41faedfbb2847527d67a9365'
});

class ShopifyClient {
    constructor() {
        this.client = client;
        this.checkout = {
            create: this.createCheckout.bind(this),
            fetch: this.fetchCheckout.bind(this),
            addLineItems: this.addLineItems.bind(this)
        };
    }

    async createCheckout() {
        return this.client.checkout.create();
    }

    async fetchCheckout(checkoutId) {
        return this.client.checkout.fetch(checkoutId);
    }

    async addLineItems(checkoutId, lineItems) {
        try {
            const checkout = await this.client.checkout.create();
            const variantId = window.btoa(`gid://shopify/ProductVariant/${lineItems[0].variantId}`);
            
            const addItems = await this.client.checkout.addLineItems(checkout.id, [{
                variantId: variantId,
                quantity: lineItems[0].quantity
            }]);

            window.location.href = addItems.webUrl;
            return addItems;
        } catch (error) {
            console.error('Error adding to cart:', error);
            throw error;
        }
    }
}

// Create a singleton instance
const shopifyClient = new ShopifyClient();

// Named exports for individual functions
export const createCart = async () => shopifyClient.checkout.create();
export const addToCart = async (variantId, quantity = 1) => {
    return shopifyClient.checkout.addLineItems('', [{
        variantId,
        quantity
    }]);
};

// Default export for compatibility with existing code
export default shopifyClient;
