import shopifyClient from '../config/shopify.js';

let checkoutId = null;

export default () => ({
    checkoutId: null,
    cartItems: [],
    webUrl: null,
    totalPrice: 0,
    cartCount: 0,
    open: false,
    init() {
        this.fetchExistingCheckout();

        // Listen for add to cart events
        window.addEventListener('cart:add-item', async (event) => {
            const { variantId, quantity } = event.detail;
            
            try {
                if (!this.checkoutId) {
                    const checkout = await shopifyClient.checkout.create();
                    this.checkoutId = checkout.id;
                    localStorage.setItem('shopify_checkout_id', this.checkoutId);
                }

                const lineItems = [{
                    variantId: window.btoa(`gid://shopify/ProductVariant/${variantId}`),
                    quantity: quantity
                }];

                const updatedCheckout = await shopifyClient.checkout.addLineItems(this.checkoutId, lineItems);
                
                this.cartItems = this.transformLineItems(updatedCheckout.lineItems);
                this.calculateTotalPrice();
                this.calculateCartCount();
                this.open = true;

                // Redirect to checkout
                window.location.href = updatedCheckout.webUrl;
            } catch (error) {
                console.error('Error adding item to cart:', error);
            }
        });
    },

    formatPrice(price, currency) {
        return new Intl.NumberFormat(navigator.language, {
            style: 'currency',
            currency: currency,
        }).format(price);
    },

    transformLineItems(lineItems) {
        return lineItems.map(item => ({
            id: item.attrs.id?.value,
            title: item.attrs.title?.value ?? 'No title',
            quantity: item.attrs.quantity?.value ?? 0,
            variantId: item.attrs.variant?.attrs.id?.value,
            price: item.attrs.variant?.attrs.priceV2?.attrs.amount?.value ?? '0',
            currency: item.attrs.variant?.attrs.priceV2?.attrs.currencyCode?.value ?? 'USD',
            priceFormatted: this.formatPrice(
                item.attrs.variant?.attrs.priceV2?.attrs.amount?.value ?? 0,
                item.attrs.variant?.attrs.priceV2?.attrs.currencyCode?.value ?? 'USD'
            ),
            imageUrl: item.attrs.variant?.attrs.image?.attrs.src?.value ?? 'default_image_url',
            altText: item.attrs.variant?.attrs.image?.attrs.altText?.value ?? item.attrs.title?.value ?? 'No image description',
        }));
    },


    async fetchExistingCheckout() {
        // this.checkoutId = localStorage.getItem('shopify_checkout_id');
        checkoutId = localStorage.getItem('shopify_checkout_id')
        this.checkoutId = checkoutId
        if (!this.checkoutId) return;

        try {
            const checkout = await shopifyClient.checkout.fetch(this.checkoutId);
            this.cartItems = this.transformLineItems(checkout.lineItems);
            this.calculateTotalPrice();
            this.calculateCartCount();

            this.webUrl = checkout.webUrl;
        } catch (error) {
            console.error('Failed to fetch existing checkout:', error);
        }
    },

    calculateTotalPrice() {
        const totalPrice = this.cartItems.reduce((total, item) => {
            return total + (parseFloat(item.price) * item.quantity);
        }, 0);

        // Assuming all items have the same currency, get currency of the first item
        const currency = this.cartItems[0]?.currency || 'USD';
        this.totalPrice = this.formatPrice(totalPrice, currency);
    },

    async removeLineItem(lineItemId) {
        if (!this.checkoutId || !lineItemId) return;

        try {
            const updatedCheckout = await shopifyClient.checkout.removeLineItems(this.checkoutId, [lineItemId]);
            this.cartItems = this.transformLineItems(updatedCheckout.lineItems);
            this.calculateTotalPrice();
            this.calculateCartCount();

        } catch (error) {
            console.error('Failed to remove item from cart:', error);
        }
    },

    async updateLineItemQuantity(lineItemId, quantity) {
        if (!this.checkoutId || !lineItemId) return;

        const intQuantity = parseInt(quantity, 10);
        if (isNaN(intQuantity) || intQuantity <= 0) {
            console.error('Quantity must be a positive integer');
            return;
        }


        try {
            const lineItemsToUpdate = [{id: lineItemId, quantity: intQuantity}];
            const updatedCheckout = await shopifyClient.checkout.updateLineItems(this.checkoutId, lineItemsToUpdate);
            this.cartItems = this.transformLineItems(updatedCheckout.lineItems);
            this.calculateTotalPrice();
            this.calculateCartCount();

        } catch (error) {
            console.error('Failed to update item quantity:', error);
        }
    },
    calculateCartCount() {
        const count = this.cartItems.reduce((total, item) => total + item.quantity, 0);
        this.cartCount = count > 0 ? count : false;
        if (count < 1) {
            this.open = false;
        }
    },

    getCheckoutLink() {
        return this.webUrl || null;
    },
});
