import { addToCart } from '../config/shopify';

export default () => ({
    adding: false,
    quantity: 1,
    
    addToCart() {
        if (this.adding) return;
        this.adding = true;
        
        setTimeout(() => {
            window.location.href = `https://teensyindustries.myshopify.com/cart/${this.variantId}:${this.quantity}`;
        }, 500);
    }
});